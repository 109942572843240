.stepper-wrapper {
  width: fit-content;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.step-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
}

.step-wrapper:not(:last-child)::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 50%;
  height: 3px;
  background-color: var(--border-stepper-color);
}

.step-wrapper.completed:not(:last-child)::before {
  background-color: var(--ui-blue-8);
}

.step {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--bg-stepper-color);
  border: 1px solid var(--ui-gray-6);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
}

.step-name {
  text-align: center;
  padding: 0 4px;
  min-width: 100%;
  box-sizing: border-box;
}

/* Ensure the step takes up at least as much space as its content */
.step-wrapper {
  font-weight: 500;
  min-width: min-content;
}

/* Adjust the connecting line */
.step-wrapper:not(:last-child)::before {
  width: 100%;
  transform: translateX(15px);
}

/* Optional: Style for completed steps */
.step-wrapper.completed .step {
  background-color: var(--ui-blue-8);
  border-color: var(--ui-blue-8);
  color: white;
}

/* Optional: Style for active step */
.step-wrapper.active .step {
  border-color: var(--ui-blue-8);
  border-width: 2px;
}

.step-wrapper.active {
  content: none;
}

.step-wrapper.active .step {
  background: var(--bg-stepper-active-color);
  border: 2px solid var(--ui-blue-8);
}

.step-wrapper.active .step-counter {
  color: var(--text-stepper-active-color);
}

.step-wrapper.completed .step {
  background: var(--bg-stepper-active-color);
  border: 2px solid var(--ui-blue-8);
}

.step-wrapper.completed .step-counter {
  color: var(--text-stepper-active-color);
}

.step-wrapper:last-child::after {
  content: none;
}
