/* Bring offscreen, but allow it to be focusable for accessibility */
.group input {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.group input:checked + label {
  color: #fff;
  background-color: #286090;
  background-image: none;
  border-color: #204d74;
}
